import { Button } from '@audi/audi-ui-react';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { addSearchParams } from '@oneaudi/feature-app-utils';
import { FAStateContext } from '../context/FAStateContext';
import { UniversalStateContext } from '../context/UniversalStateContext';
import { useTrackingManager } from '../hooks/useTracking';
import { getRelatedProductInformation } from '../utils/trackingUtils';
import { ContentContext } from '../context/ContentContext';

const Container = styled.div`
  margin-block-end: var(${({ theme }) => theme.responsive.spacing.l});
`;

export const ConfigureButton: FC<{ className?: string }> = ({ className }) => {
  const { i18nTexts } = useContext(UniversalStateContext)!;
  const trackingManager = useTrackingManager();
  const { carline } = useContext(FAStateContext);
  const {
    carline: { configuratorLink },
    idString,
    colorState: [color],
    rimState: [rim],
  } = useContext(FAStateContext);
  const { carlineId } = useContext(ContentContext);

  const linkWithParams = useMemo(() => {
    if (!configuratorLink) {
      return null;
    }
    //  only for testing
    // const BASE_URL = 'https://www.audi.de';
    // let testLink = `${BASE_URL}${configuratorLink}`;
    // testLink = testLink.replace('shop-the-look', 'konfigurator');

    if (!idString) {
      return configuratorLink;
    }
    return addSearchParams(configuratorLink, new Map([['pr', idString]]));
  }, [idString]);

  if (!linkWithParams) {
    return null;
  }

  const trackCTA = () => {
    const relatedProductInfo = getRelatedProductInformation(carline, [color, rim]);
    trackingManager.click({
      eventAction: 'internal_link', // todo check if internal or external
      eventName: "click on 'continue with configuration'",
      elementName: 'button',
      value: carlineId,
      targetURL: configuratorLink,
      relatedProducts: relatedProductInfo,
      label: i18nTexts.configureLinkText,
    });
  };

  return (
    <Container className={className}>
      <Button variant="primary" href={linkWithParams} onClick={trackCTA} size="small">
        {i18nTexts.configureLinkText}
      </Button>
    </Container>
  );
};
