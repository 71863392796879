import type { PrimaryCartegories, RelatedProducts } from '../hooks/useTracking';
import type { MappedCar } from './mapCarline';

/* eslint-disable no-restricted-syntax */
export const checkLinkTarget = (url: string) => {
  const tempLink = document.createElement('a');
  tempLink.href = url;

  if (tempLink.hostname === window.location.hostname) {
    return 'internal_link';
  }
  return 'exit_link';
};

export const completeTargetURL = (targetURL: string) => {
  const { host } = document.location;
  if (targetURL.startsWith('/')) {
    return `${host}${targetURL}`;
  }
  return targetURL;
};

export const getTrackingSearchParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const keys = ['bytype', 'bybodytype', 'bycarlinegroup', 'byvehicletype'];

  for (const key of keys) {
    const value = urlParams.get(key);
    if (value) {
      return value;
    }
  }

  return '';
};

export const getRelatedProductInformation = (
  carline: MappedCar,
  prStrings: string[],
): RelatedProducts | undefined => {
  const items = [...carline.exteriorColors, ...carline.rims];

  const selectedItems = prStrings.flatMap(
    (prString) =>
      items
        .map((item) => {
          if (item.pr3 === prString || item.pr7 === prString) {
            return {
              productInfo: {
                productId: item.pr3, // Using item.pr3 for productId
                productName: item.name,
                manufacturer: 'Audi',
              },
              category: {
                primaryCategory: carline.exteriorColors.includes(item)
                  ? 'EXTERIOR_COLOR'
                  : ('RIM' as PrimaryCartegories),
                productType: 'car part' as const,
              },
              attributes: {
                prString7: item.pr7 || 'unknown',
              },
            };
          }
          return null;
        })
        .filter((item): item is NonNullable<typeof item> => item !== null), // Filter out null values
  );

  if (selectedItems.length === 0) {
    console.error(`No matching products found for prStrings: ${prStrings.join(', ')}`);
    return undefined;
  }

  return {
    selectedItem: selectedItems,
  };
};

export const getGroupIdfromCarlineId = (carlineId: string) => `ÈTRONG - ${carlineId}`;
