import { ThemeProvider } from '@audi/audi-ui-react';
import { FootnoteContextProvider, InViewContextProvider } from '@oneaudi/feature-app-utils';
import { OneGraphProvider } from '@oneaudi/onegraph-client';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import FeatureApp from './FeatureApp';
import { ContentContextProvider } from './context/ContentContext';
import type { FeatureAppEnvContextValue } from './context/FeatureAppEnvContext';
import { FeatureAppEnvContext } from './context/FeatureAppEnvContext';
import { UniversalStateContext } from './context/UniversalStateContext';
import type { UniversalState } from './utils/createUniversalState';

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

interface FeatureAppWrapperProps {
  readonly env: FeatureAppEnvContextValue;
  readonly universalState?: UniversalState;
}

export const FeatureAppWrapper: FC<FeatureAppWrapperProps> = ({ env, universalState }) => {
  const oneGraphClient = env.featureServices['onegraph-service'].getApolloClient();
  const contentService = env.featureServices['audi-content-service'];
  const renderModeService = env.featureServices['audi-render-mode-service'];
  const footnoteReferenceService =
    env.featureServices['audi-footnote-reference-service']?.getDefaultScopeRefService();

  return (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <ThemeProvider>
        <FeatureAppEnvContext.Provider value={env}>
          <ContentContextProvider contentService={contentService}>
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <Wrapper data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                <OneGraphProvider client={oneGraphClient}>
                  <UniversalStateContext.Provider value={universalState}>
                    <InViewContextProvider>
                      <FeatureApp />
                    </InViewContextProvider>
                  </UniversalStateContext.Provider>
                </OneGraphProvider>
              </Wrapper>
            </FootnoteContextProvider>
          </ContentContextProvider>
        </FeatureAppEnvContext.Provider>
      </ThemeProvider>
    </UniversalEditorProvider>
  );
};
