import React, { useRef, useCallback, useContext, useState } from 'react';
import { FAStateContext } from '../../context/FAStateContext';
import { useScrollButtons } from '../../hooks/useScrollButtons';
import { useContainerWidth } from '../../hooks/useContainerWidth';
import { Selector } from './Selector';
import { Container } from './FeatureSelector.styles';
import type { ConfiguredCarFeature } from '../../utils/mapCarline';
import { useTrackingManager } from '../../hooks/useTracking';
import { ContentContext } from '../../context/ContentContext';

// move active Color to first pos on toggle
const sortColor =
  (color: string) =>
  (a: ConfiguredCarFeature, b: ConfiguredCarFeature): number => {
    if (a.pr3 === color) {
      return -1;
    }
    if (b.pr3 === color) {
      return 1;
    }
    return 0;
  };

// move active Rim to first pos on toggle
const sortRim =
  (rim: string) =>
  (a: ConfiguredCarFeature, b: ConfiguredCarFeature): number => {
    if (a.pr7 === rim) {
      return -1;
    }
    if (b.pr7 === rim) {
      return 1;
    }
    return 0;
  };

export const FeatureSelector = () => {
  const { carline, colorState, rimState } = useContext(FAStateContext);
  const [color, setColor] = colorState;
  const [colors, setColors] = useState(() => carline.exteriorColors.sort(sortColor(color)));
  const [rim, setRim] = rimState;
  const [rims, setRims] = useState(() => carline.rims.sort(sortRim(rim)));
  const [colorSelectorOpen, setColorSelectorOpen] = useState(true);
  // References for the color and rim containers
  const refColors = useRef<HTMLDivElement>(null);
  const refRims = useRef<HTMLDivElement>(null);
  const { carlineId } = useContext(ContentContext);

  // Calculate the container width based on the viewport size and the number of items
  const containerWidth = useContainerWidth(colorSelectorOpen, colors.length, rims.length);

  // Hook to handle scrolling functionality
  const { showBackButton, showForwardButton, handleScroll } = useScrollButtons(
    colorSelectorOpen ? refColors : refRims,
    colorSelectorOpen ? carline.exteriorColors.length : carline.rims.length,
    colorSelectorOpen ? 'colors' : 'rims',
  );
  const trackingManager = useTrackingManager();

  const toggleSelector = useCallback(() => {
    setColorSelectorOpen((current) => {
      const nextState = !current;

      setColors(() => carline.exteriorColors.sort(sortColor(color)));
      setRims(() => carline.rims.sort(sortRim(rim)));

      trackingManager.click({
        eventAction: 'content',
        eventName: `click on ${nextState ? 'colors' : 'rims'}`, // Use nextState instead of colorSelectorOpen
        elementName: 'icon',
        value: carlineId,
        label: '',
      });
      return nextState;
    });
  }, [color, rim]);

  const colorClickHandler = useCallback(
    (pr: string) => {
      if (colorSelectorOpen) {
        setColor(pr);
      }
    },
    [colorSelectorOpen],
  );
  const rimClickHandler = useCallback(
    (pr: string) => {
      if (!colorSelectorOpen) {
        setRim(pr);
      }
    },
    [colorSelectorOpen],
  );

  return (
    <Container>
      {/* Color Selector */}
      {colors.length > 1 && (
        <Selector
          items={colors}
          selectedItem={color}
          onItemClick={colorClickHandler}
          open={colorSelectorOpen}
          containerWidth={containerWidth}
          showBackButton={showBackButton}
          showForwardButton={showForwardButton}
          handleScroll={(isScrollForward) => handleScroll(isScrollForward)}
          toggleSelector={toggleSelector}
          refContainer={refColors}
        />
      )}
      {/* Rim Selector */}
      {rims.length > 1 && (
        <Selector
          type="rim"
          items={rims}
          selectedItem={rim}
          onItemClick={rimClickHandler}
          open={!colorSelectorOpen}
          containerWidth={containerWidth}
          showBackButton={showBackButton}
          showForwardButton={showForwardButton}
          handleScroll={(isScrollForward) => handleScroll(isScrollForward)}
          toggleSelector={toggleSelector}
          refContainer={refRims}
        />
      )}
    </Container>
  );
};
