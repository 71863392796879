import type { ChangeConfiguredCarFeatureQuery } from '../../graphql/documents/change-configured-car.generated';
import type {
  ConfiguredCarCatalogImage,
  CustomizerMainQuery,
  Maybe,
  RenderedCarImage,
  VehicleType,
} from '../../graphql/documents/customizer-main.generated';

export interface TypedConfiguredCarCatalogImages {
  __typename?: 'TypedConfiguredCarCatalogImages';
  images: Maybe<ConfiguredCarCatalogImage[]>;
}

export interface ConfiguredCarFeature {
  __typename?: 'ConfiguredCarFeature';
  name: string;
  pr3: string;
  pr7: string;
  selected?: boolean;
  imageUrl: string;
}

export interface MappedCarIdentifier {
  model: {
    code: string;
    version: number;
    year: number;
    extensions: string[];
  };
  exteriorColor: string;
  interiorColor: string;
  salesGroup: string;
  equipmentOptions?: string[];
}

export interface MappedCar {
  idString: string;
  name?: string;
  id: MappedCarIdentifier;
  configuratorLink?: string;
  vehicleType?: Maybe<VehicleType>;
  consumption: {
    co2Class: Maybe<string>;
    consumption: Maybe<string>;
    dischargedCO2Class: Maybe<string>;
    dischargedConsumption: Maybe<string>;
    emissionCO2: Maybe<string>;
  };
  imageAssets: string[];
  imageEnv: boolean;
  rims: ConfiguredCarFeature[];
  exteriorColors: ConfiguredCarFeature[];
  price?: number;
}

export const mapCarIdentifier = (
  id?: NonNullable<
    ChangeConfiguredCarFeatureQuery['changeConfiguredCarFeature']['configuredCar']
  >['id'],
): MappedCarIdentifier => ({
  model: {
    code: id?.model.code || '',
    version: id?.model.version || 0,
    year: id?.model.year || 0,
    extensions: id?.model.extensions || [],
  },
  exteriorColor: id?.exteriorColor || '',
  interiorColor: id?.interiorColor || '',
  salesGroup: id?.salesGroup || '',
  equipmentOptions: id?.equipmentOptions || [],
});

type Image = {
  url: string[];
  isFancy: boolean;
};

export function getImageAssets(
  media: Pick<RenderedCarImage, 'url' | 'profileId'>[] | undefined,
): Image {
  if (!media) {
    return { url: [], isFancy: false };
  }
  // Filter images where profileId ends with '-ENV1' for environmetal images
  const envImages = media
    .filter((image) => image.profileId.endsWith('-ENV1'))
    .map((image) => image.url);

  // Filter images where profileId does not end with '-ENV1' for fancy images
  const fancyImages = media
    .filter((image) => !image.profileId.endsWith('-ENV1'))
    .map((image) => image.url);

  let imageUrls: string[] = [];

  if (fancyImages.length > 0) {
    imageUrls = fancyImages;
  } else if (envImages.length > 0) {
    imageUrls = envImages;
  }

  return {
    url: imageUrls,
    isFancy: fancyImages.length > 0,
  };
}

export const mapConfiguredCar = (data: CustomizerMainQuery): MappedCar => {
  const {
    configuredCarByCarline: { carlineStructureCarline, media, catalog, id, idString: rawIdString },
  } = data;
  const idString: string = typeof rawIdString === 'string' ? rawIdString : '';
  const { carline } = data;
  const consumption = carline.consumptionAndEmission?.summary;
  const vehicleType = carlineStructureCarline?.vehicleType;
  const name = carline?.name || '';

  const images = getImageAssets(media?.images);
  const imageAssets = images.url;
  const imageEnv = images.isFancy;

  const rims =
    catalog?.features.rims
      .filter((rim) => !!rim.imageSets?.[0]?.images[0]?.url)
      .map(
        (rim): ConfiguredCarFeature => ({
          name: rim.name || '',
          pr3: rim.pr3,
          pr7: rim.pr7,
          selected: rim.status.selected,
          imageUrl: rim.imageSets![0].images[0]!.url!,
        }),
      ) || [];

  const exteriorColors =
    catalog?.features.exteriorColors
      .filter((color) => {
        const imageUrl = color.imageSets?.[0]?.images[0]?.url;
        const pr3 = color.pr3?.length === 4 ? color.pr3 : '';
        const pr7 = color.pr7?.length === 8 ? color.pr7 : '';

        return (
          !!imageUrl &&
          pr3 &&
          pr7 &&
          pr3.slice(0, 2) === pr3.slice(2, 4) && // (2D2D) char 1&2 = base, char 3&4 = roof, remove if not identical (WEBSUPPORT-50710)
          pr7.slice(4, 6) === pr7.slice(6, 8)
        );
      })
      .map(
        (color): ConfiguredCarFeature => ({
          name: color.name || '',
          pr3: color.pr3,
          pr7: color.pr7,
          selected: color.status.selected,
          imageUrl: color.imageSets![0].images[0]!.url!,
        }),
      ) || [];

  return {
    idString,
    name,
    vehicleType,
    imageAssets,
    imageEnv,
    rims,
    exteriorColors,
    id: mapCarIdentifier(id),
    consumption: {
      co2Class: consumption?.co2Class ?? null,
      consumption: consumption?.consumption ?? null,
      dischargedCO2Class: consumption?.dischargedCO2Class ?? null,
      dischargedConsumption: consumption?.dischargedConsumption ?? null,
      emissionCO2: consumption?.emissionCO2 ?? null,
    },
    configuratorLink: carline.links?.configuratorPage?.url || undefined,
    price: carline.prices.minPrice?.value,
  };
};
