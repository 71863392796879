import React from 'react';
import type { TrackingDataV2 } from '@oneaudi/audi-tracking-service';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import { checkLinkTarget, completeTargetURL, getTrackingSearchParam } from '../utils/trackingUtils';

export interface TrackingManager {
  ready: (version: string) => void;
  impression(carline: string): void;
  click: (params: ClickEventParams) => void;
  clickToChangeCarPart: (
    eventName: string,
    value: string,
    relatedProducts: RelatedProducts,
  ) => void;
  trackScrollOrSwipeEvent(type: string, value?: string): void;
}

export interface ClickEventParams {
  eventAction: 'content' | 'navigation' | 'image' | 'internal_link' | 'external_link';
  eventName: string;
  elementName: 'icon' | 'image' | 'arrow' | 'button';
  value?: string;
  pos?: string;
  targetURL?: string;
  relatedProducts?: RelatedProducts;
  label?: string;
}

export type PrimaryCartegories = 'EXTERIOR_COLOR' | 'EXCLUSIVE_EXTERIOR_COLOR' | 'RIM';

export interface RelatedProducts {
  selectedItem: Array<{
    productInfo: {
      productId: string;
      productName: string;
      manufacturer: string;
    };
    category: {
      primaryCategory: PrimaryCartegories;
      productType: string;
    };
    attributes: {
      prString7: string;
    };
  }>;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
  componentUpdate: {
    componentInfo: {
      implementer: number;
      version: string;
    };
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingDataV2 {
  const defaultAttributes = {
    componentName: 'customizer',
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentInfo: {
      implementer: 2,
      version,
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const { 's2:logger': logger, 'audi-tracking-service': trackingService } =
    useFeatureAppEnv().featureServices;

  return React.useMemo((): TrackingManager => {
    const track = (...args: Parameters<typeof createTrackingPayload>): void => {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) {
          logger.debug('Sending tracking event: ', JSON.stringify(payload, null, 2));
        }
        trackingService.track(payload);
      }
    };

    const trackWithComponent = (
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void => {
      if (trackingService) {
        const payload = createTrackingPayload(...args);

        const componentPayload = createComponentPayload(version);

        logger?.debug('Sending tracking event: ', {
          ...payload,
          datalayerAdditions: componentPayload,
        });

        const x: TrackingDataV2 = {
          ...payload,
          componentUpdate: componentPayload,
        };
        trackingService.track(x);
      }
    };
    const ready = (version: string) => {
      const search = getTrackingSearchParam();
      trackWithComponent(version, 'feature_app_ready', 'customizer - feature app ready', {
        label: '',
        targetURL: '',
        clickID: '',
        elementName: '',
        value: '',
        pos: '',
        search,
      });
    };

    const impression = (carline: string) => {
      track('impression', `customizer - impression`, {
        label: '',
        targetURL: '',
        clickID: '',
        elementName: '',
        value: carline,
        pos: '',
      });
    };

    const click = ({
      eventAction,
      eventName,
      elementName,
      value,
      pos = '',
      targetURL,
      relatedProducts,
      label = '',
    }: ClickEventParams) => {
      const action = targetURL ? checkLinkTarget(targetURL) : eventAction;
      track(action, `customizer - ${eventName}`, {
        elementName,
        value,
        targetURL: targetURL ? completeTargetURL(targetURL) : '',
        pos,
        relatedProducts: relatedProducts || '',
        label,
        clickID: '',
      });
    };

    const clickToChangeCarPart = (
      eventName: string,
      value: string,
      relatedProducts: RelatedProducts,
    ) => {
      track('content', `customizer - click on '${eventName}'`, {
        elementName: 'icon',
        value,
        relatedProducts,
      });
    };

    const trackScrollOrSwipeEvent = (type: string, value: string) => {
      track('navigation', `customizer - scroll in ${type}s`, {
        elementName: 'other',
        value,
        label: '',
        pos: '',
      });
    };

    return {
      ready,
      click,
      clickToChangeCarPart,
      impression,
      trackScrollOrSwipeEvent,
    };
  }, [trackingService]);
}
